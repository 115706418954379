import React from "react"
import Contact from "../components/Contact";
import Hero from "../components/Hero"
import Footer from "../components/utility/Footer"
import Navbar from "../components/utility/Navbar"
import Projects from "../components/Projects";
import heroImage from "../images/haus-07.jpg";
import { jobsData } from "../utility/jobsData";
import { Helmet } from "react-helmet";

const heroData = {
  headline: "Wir suchen Zimmerleute",
  text: "Wir sind ein starkes, gewachsenes Team aus Älteren mit großer Erfahrung und Jungen voller Tatendrang. Aber inzwischen können wir gut Verstärkung gebrauchen und vielleicht wärst ja gerade Du der oder die Richtige ?",
  image: heroImage,
  alt: "Ein Haus mit großer Glasfassade"
}

const JobsPage = () => {
  return (
    <>
      <Helmet title="Zimmerei Müller - Jobs">
        <meta property="og:title" content="Zimmerei Müller - Jobs" />
        <meta
          property="og:description"
          content="Wir suchen Zimmerleute! Wir sind ein leistungsstarkes, gewachsenes Team aus erfahrenen Älteren und Jungen voller Tatendrang. Aber inzwischen können wir gut Verstärkung gebrauchen und vielleicht wärst ja gerade Du der oder die Richtige ?"
        />
        <meta property="og:url" content="https://holzbaumueller.de/jobs" />
        <meta
          name="description"
          content="Wir suchen Zimmerleute! Wir sind ein leistungsstarkes, gewachsenes Team aus erfahrenen Älteren und Jungen voller Tatendrang. Aber inzwischen können wir gut Verstärkung gebrauchen und vielleicht wärst ja gerade Du der oder die Richtige ?"
        />
      </Helmet>
      <Navbar />
      <main>
        <Hero headline={heroData.headline} text={heroData.text} image={heroData.image} alt={heroData.alt} />
        <Projects headline={jobsData.headline} hasLines={true} projects={jobsData.data} />
        <Contact />
      </main>
      <Footer />
    </>
  )
}

export default JobsPage